import "./header.scss";
import * as dompack from 'dompack';

dompack.onDomReady(() => {

let stickymenu = dompack.qS("header")
let documentbody = dompack.qS("body")
let stickymenuoffset = stickymenu.offsetTop

  window.addEventListener("scroll", function(e)
  {
      requestAnimationFrame(function()
      {
          if (window.pageYOffset > stickymenuoffset){
              stickymenu.classList.add('smallheader')
              documentbody.classList.add('scroll')
          }
          else{
              stickymenu.classList.remove('smallheader')
              documentbody.classList.remove('scroll')
          }
      })
  })

});
