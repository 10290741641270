import "./search.scss";
import * as dompack from 'dompack';

dompack.register(".searchform", node => {
  node.addEventListener("submit", ev => {
    if( !node.words.value )
      ev.preventDefault();
  });

  let words = getUrlParam("words");//prefill search input from url parameter
  if( words )
    node.words.value = words;
});

function getUrlParam(name)
{
  let urlparamstr = location.search.replace(/\+/g,"%20");
  let val  = ( new RegExp('[?&]' + encodeURIComponent(name) + '=([^&]*)') ).exec(urlparamstr);
  return val ? decodeURIComponent(val[1]) : "";
}
