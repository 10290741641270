import * as dompack from 'dompack';
import * as browser from 'dompack/extra/browser';
import './pagination.scss';

let __pagination_register = [];

/*
Instructions:
add <div class="wh-pagination" data-paginate-items=".summary > ul > li" data-paginate-maxpp="10" data-paginate-maxpagethumbs="9"></div>
into desired place in page
  'data-paginate-items' should contain the query selector for the items you want apply the pagination on
  'data-paginate-maxpp' max. number of items shown on a page
  'data-paginate-maxpagethumbs' max. number of pagination thumbs to show in pagination bar
styling can be found in pagination.scss

Note: You can add multiple page navigation bars in page.
      If an other navigation bar controls the same items it will automaticly be updated
*/


class cPagination
{
  constructor(node)
  {
    if( !node.dataset.paginateItems || !node.dataset.paginateMaxpp )
      return;

    this.node = node;

    this.items = dompack.qSA( this.node.dataset.paginateItems );
    if( !this.items )
      this.destroy(); //No items for pagination found

    this.maxpp = parseInt( this.node.dataset.paginateMaxpp );
    if( !this.maxpp || this.maxpp < 1 )
      this.destroy(); //Max per page not set, no pagination

    this.pagecount = ~~((this.items.length + this.maxpp - 1) / this.maxpp);
    if( this.pagecount <= 1 )
      this.destroy();//Just one page, no pagination needed

    this.maxpagethumbs = parseInt( this.node.dataset.paginateMaxpagethumbs );
    if( !this.maxpagethumbs || this.maxpagethumbs <= 0 )
      this.maxpagethumbs = this.pagecount;//show all pagethumbs

    this.page = -1;

    let startpage = parseInt( this.getUrlParam("page") );
    if(!startpage)
      startpage = 0;//force integer incase page parameter not set

    this.gotoPage(startpage, true);

    //Register current pagination so we can check for others using them same items
    this.firstitem = this.items[0];
    __pagination_register.push( {node : this.node, obj : this, firstitem : this.firstitem } );

    //Check for clicks inside navigation
    this.node.addEventListener("click", ev => this.onClick(ev));
  }

  destroy()
  { //No pagination needed, then also remove the pagination node from DOM
    this.node.parentNode.removeChild(this.node);
  }

  buildPagination(idx)
  {
    dompack.empty(this.node);

    let startidx = 0;
    if( this.maxpagethumbs && this.maxpagethumbs < this.pagecount )
    {
      startidx = idx - ~~(this.maxpagethumbs/2);
      if( startidx < 0 )
        startidx = 0;
    }

    if( startidx > 0 )
      this.node.appendChild(<li><span /></li>);

    for( let c = startidx; c < startidx + this.maxpagethumbs && c < this.pagecount; ++c )
      this.node.appendChild(<li class={ c == idx ? "active" : ""}><a href={"./?page=" + c} data-page={c}>{c+1}</a></li>);

    if(startidx + this.maxpagethumbs < this.pagecount - 1)
      this.node.appendChild(<li><span /></li>);
  }

  onClick(ev)
  {
    let anode = dompack.closest(ev.target,"a");
    if(!anode)
      return;

    dompack.stop(ev);
    let idx = parseInt(anode.dataset.page);
    if(idx != this.page)
      this.gotoPage(idx);
  }

  gotoPage(idx, initial)
  {
    this.page = idx;

    //Check if page(nr) is in valid range
    if(this.page < 0)
      this.page = 0;
    else if(this.page >= this.pagecount)
      this.page = this.pagecount - 1;

    if( window.history && window.history.replaceState )
    {
      let baseurl = window.location.href.split('?')[0];
      history.replaceState(undefined, undefined, baseurl + "?page=" + this.page);
    }

    //Check of there is also an other pagenavigation for same items
    // If so, then also update also other pagination
    for( let c = 0; !initial && c < __pagination_register.length; ++c )
    {
      if( __pagination_register[c].node != this.node && __pagination_register[c].firstitem == this.firstitem )
        __pagination_register[c].obj.buildPagination( this.page );
    }

    //show/hide items
    for( let c = 0; c < this.items.length; ++c )
      this.items[c].style.display = c >= this.page*this.maxpp && c < this.page*this.maxpp + this.maxpp ? "" : "none";

    if( !initial )
      this.node.parentNode.scrollIntoView( this.supportsScrollIntoViewBehavior() ? {block:'start', behavior:'smooth'} : true );

    this.buildPagination(this.page);
  }

  supportsScrollIntoViewBehavior() //http://caniuse.com/#feat=scrollintoview
  {
    return ["firefox","chrome"].includes( browser.getName() );
  }

  getUrlParam(name)
  {
    return new URL( location.href ).searchParams.get( name );
  }
}

dompack.register(".wh-pagination", node => new cPagination( node ) );
