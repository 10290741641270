import * as whintegration from '@mod-system/js/wh/integration';

const cookiesAccepted = getWithExpiry('cookiesAccepted');

if (cookiesAccepted === true) {
    handleConsentChange({ consent: ["granted"] });
} else if (cookiesAccepted === false) {
    handleConsentChange({ consent: [] });
} else {
    startCookieRequest();
}

function startCookieRequest() {
    if (window.top !== window.self) {
        return;
    }

    const status = { consent: [] };
    handleConsentChange(status);

    let duration = 1000;

    let animtimer = setTimeout(function () 
    {
        let cookiemsg = document.querySelector('.cookiebar__notice');
        if(!cookiemsg)
            return;
        document.documentElement.classList.add('website--showcookiebar');
        cookiemsg.classList.remove("d-none");

        for (let btnnode of cookiemsg.querySelectorAll("button")) {
            btnnode.addEventListener("click", ev => {

                if (btnnode.classList.contains("accept-cookie")) {
                    setWithExpiry('cookiesAccepted', true, 604800000); // 7 days in milliseconds
                    handleConsentChange({ consent: ["granted"] });
                } else {
                    setWithExpiry('cookiesAccepted', false, 604800000); // 7 days in milliseconds
                    handleConsentChange({ consent: [] });
                }

                clearTimeout(animtimer);

                document.documentElement.classList.remove('website--showcookiebar');
                cookiemsg.parentNode.removeChild(cookiemsg)
            });
        }

    }, duration);
}


function handleConsentChange(status) 
{
    window.dataLayer = window.dataLayer || [];
    function gtag() { window.dataLayer.push(arguments); }
    const consentValue = status.consent.length ? status.consent.join(' ') : "denied";
    
    if (!getWithExpiry('cookiesAccepted')) {
        gtag("consent", "default", {
            ad_storage: "granted",
            ad_user_data: "denied",
            ad_personalization: "denied",
            analytics_storage: "granted",
            functionality_storage: "denied",
            personalization_storage: "denied",
            security_storage: "granted"
        });
    } else {
        gtag("consent", "update", {
            ad_storage: consentValue,
            ad_user_data: consentValue,
            ad_personalization: consentValue,
            analytics_storage: "granted",
            functionality_storage: consentValue,
            personalization_storage: consentValue,
            security_storage: "granted"
        });
        gtag('event', 'fp-consentchange');
    }
}

function setWithExpiry(key, value, ttl) {
    const now = new Date();
    const item = {
        value: value, 
        expiry: now.getTime() + ttl,
    };
    localStorage.setItem(key, JSON.stringify(item));
}

function getWithExpiry(key) {
    const itemStr = localStorage.getItem(key);

    if (!itemStr) {
        return null;
    }

    const item = JSON.parse(itemStr);
    const now = new Date();

    if (now.getTime() > item.expiry) {
        localStorage.removeItem(key);
        return null;
    }

    return item.value;
}