import * as dompack from 'dompack';
import 'dompack/browserfix/reset.css';

import * as whintegration from '@mod-system/js/wh/integration';
import '@mod-system/js/wh/errorreporting'; //log JS errors to notice log
import { openLinksInNewWindow } from '@mod-publisher/js/linkhandler';

import GLightbox from 'glightbox';
import './shared/glightbox.min.css';

import Glide, { Breakpoints, Autoplay, Controls  } from '@glidejs/glide/dist/glide.modular.esm';

import '@glidejs/glide/dist/css/glide.core.min.css';
import '@glidejs/glide/dist/css/glide.theme.min.css';

import * as sidebarjs from 'sidebarjs';
import 'sidebarjs/lib/sidebarjs.css';
import './shared/sidebarjs.scss';

import './shared/forms/forms';
import './shared/rtd/rtd';
import './shared/pagination.es';
import './shared/sidebarjs.es';
import './shared/cookiebanner.es';

import './cimic-coe.scss';
import './header';
import './pages';
import './widgets';
import './footer';

const lightboxVideo = GLightbox({
    selector: 'glightbox__video',
});

const lightboxGallery = GLightbox({
    selector: 'glightbox__gallery',
});


// sidemenu
new sidebarjs.SidebarElement({
    component: dompack.qS('[sidebarjs="leftSidebar"]'),
    nativeSwipeOpen: false,
    nativeSwipe: false
});


// add active class to tabs when onclick
dompack.register('.has-submenu > .submenu-toggle', clickedToggle =>
{
    clickedToggle.addEventListener('click', () =>
    {
        clickedToggle.classList.toggle('collapse--open');
        clickedToggle.nextSibling.classList.toggle('d-block');
    });
});


function initMediaGallery()
{
  let mediagallery = dompack.qS('.glide');
  if(!mediagallery)
    return;

  new Glide('.glide',
  {
    type: 'carousel',
    rewind: true,
    autoplay: 5000,
    perView: 1,
    // breakpoints: {
    //   1024: {
    //     perView: 2
    //   },
    //   600: {
    //     perView: 1
    //   }
    // }
  }).mount({Breakpoints, Autoplay, Controls});

}

dompack.onDomReady(initMediaGallery);


var links = dompack.qSA('.status-fully-booked, .status-cancelled, .status-on-hold, .status-running'), i;
for(i = 0; i < links.length; i += 1)
{
    links[i].addEventListener('click', function(e)
    {
        e.preventDefault();
    }, false);
}

openLinksInNewWindow();

const images = Array.from( document.querySelectorAll('img.wh-rtd__img') );

images.forEach(image => {
  let alt = image.getAttribute('alt');
  let width = image.getAttribute('width');

  if( alt && width )
  {
    image.insertAdjacentHTML('afterEnd', `<small class="img__caption" style="width:${width}px">${alt}</small>`);
  }
});
