import "./news.scss";
import * as dompack from 'dompack';

function createShareButtons()
{
  let current_url  = window.location.origin + window.location.pathname;
  let page_title = document.getElementsByTagName("h1")[0].innerHTML;
  let insertionpoint = dompack.qS('.sharebutton_placeholder');
  if(!insertionpoint)
    return;

  let FacebookShareButton =
    <a href={"https://www.facebook.com/sharer/sharer.php?u=" + current_url} title="Share with Facebook"><i class="fab fa-facebook"></i></a>;

  let TwitterShareButton =
    <a href={"https://twitter.com/intent/tweet?source=webclient&amp;original_referer=" + current_url} title="Share with Twitter"><i class="fab fa-twitter"></i></a>;

  let LinkedInShareButton =
    <a href={"https://www.linkedin.com/shareArticle?mini=true&amp;ro=true&amp;trk=SocialPostSharer&amp;title=" + page_title + ";url=" + current_url } title="Share with LinkIn"><i class="fab fa-linkedin"></i></a>;

  let EmailShareButton =
    <a href={"mailto:?subject=Visit this link find on https://www.cimic-coe.org;body=Hi, I found this information for you: " + page_title + "<br/>" + current_url + '<br/>Have a nice day :)'} title="Share by E-mail"><i class="fas fa-envelope"></i></a>;

  insertionpoint.appendChild(FacebookShareButton);
  insertionpoint.appendChild(TwitterShareButton);
  insertionpoint.appendChild(LinkedInShareButton);
  insertionpoint.appendChild(EmailShareButton);
}

dompack.onDomReady(createShareButtons);



